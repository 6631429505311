import { createStore } from 'vuex'
import auth from "./modules/Auth"
import data from "./modules/Data"

export default createStore({
 modules:{
   auth,
   data
 }
})

import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta:{requiredAuth:true}
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth.vue'),
    meta:{requiredAuth:false}
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.meta&&to.meta.requiredAuth){
    let flag=Boolean(localStorage.getItem("isAuth"))||false
    if(flag){
      next()
    }else{
      next({path:"/auth"})
    }

  }else{
    next()
  }
})
export default router

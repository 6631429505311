import axios from "axios"
import store from "../store"


let httpCall = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    withCredentials: false
})
httpCall.interceptors.request.use((req) => {
    let token = store.getters["auth/getToken"].accessToken
    if (token) {
        req.headers['Authorization'] = `Bearer ${token}`;
    }
    return req
})
httpCall.interceptors.response.use(async res => {
    if (res.data.status == 2000) {
        try {
            let reqConfig = res.config
            let result = await store.dispatch("auth/refreshTokenReq", {
                ref_token: store.getters["auth/getToken"].refreshToken
            })
            if (result&&result.status == 1000) {
                return httpCall(reqConfig)

            } else {
                return Promise.resolve(result)
            }
        } catch (error) {
            // eslint-disable-next-line
            console.log(error);
            Promise.reject(error)
        }
    } else {
        return res

    }

}, error => Promise.reject(error))


export default httpCall
import axios from 'axios'
import httpCall from '../../helper/axios'

export default {
    namespaced: true,
    state: {
        isAuth: false,
    },
    getters: {
        getToken() {
            return { accessToken: localStorage.getItem("at"), refreshToken: localStorage.getItem("rt"), isAuth: localStorage.getItem("isAuth") }
        }

    },
    mutations: {
        SET_TOKEN: (state, payload) => {
            localStorage.setItem("at", payload.at)
            localStorage.setItem("rt", payload.rt)
            localStorage.setItem("isAuth", true)
            state.isAuth = true
            if(payload.un){
                localStorage.setItem("uName", payload.un)
                state.uName = payload.un

            }
        },
        REMOVE_TOKEN: (state) => {
            localStorage.removeItem("at")
            localStorage.removeItem("rt")
            localStorage.removeItem("isAuth")
            localStorage.removeItem("uName")
            state.isAuth = false
            state.uName = null
        }
    },
    actions: {
        loginReq: async ({ commit }, payload) => {
            try {
                let result = await axios.post(`${process.env.VUE_APP_BASEURL}/auth/login`, payload)
                if (result && result.status == 200 && result.data) {
                    if (result.data.status == 1000 && result.data.data.active) {
                        let obj = {}
                        obj.at = result.data.data && result.data.data.accessToken
                        obj.rt = result.data.data && result.data.data.refreshToken,
                            obj.un = result.data.data && result.data.data.userName,
                            commit("SET_TOKEN", obj)
                        return result.data
                    } else {
                        return {
                            status: 1001,
                            message: "Invalid User"
                        }
                    }
                } else {
                    return alert("Login Failed,try again")

                }
            } catch (error) {
                Promise.reject(error)
            }

        },
        refreshTokenReq: async ({commit},payload) => {
            try {
                let result=await httpCall.post("/auth/refreshtoken",payload)
                if(result.status==200){
                    if(result.data.status==1000){
                        commit("SET_TOKEN",{at:result.data.data.accessToken,rt:result.data.data.refreshToken})
                    }
                }
                return result.data
            } catch (error) {
                Promise.reject(error)
            }
        },
        logout: ({ commit }) => {
            commit("REMOVE_TOKEN")
            return
        }

    }
}

import httpCall from "../../helper/axios"
export default {
  namespaced: true,
  state: {
    isLoading: false,
    env: null,
    hub: null,
    endpoint: [],
    ser: null,
    dashboardData: [],
    filteredData: [],
    dateDataLogs: {},
    searchKey:null,
    gSearchKey:null
  },
  mutations: {
    SET_DASHBOARD_DATA(state, payload) {
      state.dashboardData = payload
    },
    SET_FILTERED_DATA(state, payload){
      state.filteredData = payload
    },
    SET_SEARCH_KEY(state, payload){
      state.searchKey = payload
    },
    SET_DATE_LOGS(state,payload){
      state.dateDataLogs=payload
    },
    SET_ENV(state, payload) {
      state.env = payload
    },
    SET_HUB(state, payload) {
      state.hub = payload
    },
    SET_ENDPOINT(state, payload) {
      state.endpoint.push(payload)
    },
    SET_ENDPOINT_EMPTY(state, payload) {
      state.endpoint = payload
    },
    POP_ENDPOINT(state) {
      state.endpoint.splice(-1)
    },
    SET_SERVER(state, payload) {
      state.ser = payload
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload
    },

  },
  actions: {
    async getData({ commit }, payload) {
      try {
        commit("SET_LOADING", true)
        commit("SET_FILTERED_DATA",[])
        commit("SET_SEARCH_KEY",null)
        commit("SET_DATE_LOGS",{})
        let result = await httpCall.post(`/logs/data`, payload)
        commit("SET_LOADING", false)
        if (result && result.status == 200) {
          if (result.data.status == 1000) {
            if (typeof (result.data.data) == "string") {
              result.data.data = [{ data: result.data.data }]
            }
            commit("SET_DASHBOARD_DATA", result.data.data)
          }
          return result.data
        
        } else {
          alert(result.message || "Something went wrong")
        }
      } catch (error) {
        Promise.reject(error)
      }
    },
    async getDateData({commit},payload){
      try {
        commit("SET_LOADING", true)
        commit("SET_FILTERED_DATA",[])
        commit("SET_SEARCH_KEY",null)
        commit("SET_DASHBOARD_DATA",[])
        let result = await httpCall.post(`/logs/datelogs`, payload)
        commit("SET_LOADING", false)
        if (result && result.status == 200) {
          if (result.data.status == 1000) {
            if (typeof (result.data.data) == "string") {
              result.data.data = [{ data: result.data.data }]
            }
            commit("SET_DATE_LOGS", result.data.data)
          }
          return result.data
        
        } else {
          alert(result.message || "Something went wrong")
        }
        
      } catch (error) {
        Promise.reject(error)
      }
    }
  },
  getters: {
    getDashboardData(state) {
     
        return state.dashboardData
    },
    getFilteredData(state) {
     
      return state.filteredData
  },
    getLoading(state) {
      return state.isLoading
    },
    getEndpoint(state) {
      return state.endpoint
    },
    getEnv(state) {
      return state.env
    },
    getHub(state) {
      return state.hub
    },
    getServer(state) {
      return state.ser
    },
    getSearchKey(state){
      return state.searchKey
    },
    getDateDateLogs(state){
      return state.dateDataLogs
    }
  }
}
